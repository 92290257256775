import React, { useEffect } from 'react'

const OhifViewerIframe = ({ src }) => {

  useEffect(() => {
    console.log('src', src)
  }, [src])

  return (
      <iframe
        src={src}
        title="OHIF Viewer"
        width="100%"
        height="100%"
        style={{ border: 'none', borderRadius: '19px', backgroundColor: '#14304c'}}
        allowFullScreen
      />
  );
};

export default OhifViewerIframe;
        