import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import styles from "./ProjectSelector.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUserSelectedProject } from "../../store/actions/projects";
import { getSelectorOptionsThunk } from "../../store/actions/selectorOptions";
import { setProjectSelectedAtLogin } from "../../store/reducers/projects/projectsSlicer";
import { getNextImage } from "../../store/actions/images/next";
const token = localStorage.getItem("token");

const ProjectSelector = () => {
  const { projects } = useSelector((state) => state.projects);
  const projectAtLoginData = useSelector(
    (state) => state.projects.projectSelectedAtLogin
  );
  const dispatch = useDispatch();
  const projectList = projects;

  const navigate = useNavigate();

  const handleProjectSelect = async (projectId) => {
    console.log('project selector next', token)
    await dispatch(getNextImage(localStorage.getItem("token"), projectId, projectAtLoginData));
    await dispatch(setUserSelectedProject(projectId));
    await dispatch(getSelectorOptionsThunk(projectId));
    await dispatch(setProjectSelectedAtLogin(projectId));
    navigate(`/authorized/projects/${projectId}`);
  };

  return (
    <div className={styles.projectSelector}>
      <Card className={styles.card}>
        <Card.Body>
          <Card.Title className={styles.projectSelectorTitle}>
            Select a project:
          </Card.Title>
          <ListGroup className={styles.projectList}>
            {projectList.map((project, i) => (
              <ListGroup.Item
                key={project._id}
                className={styles.projectListItem}
                onClick={() => handleProjectSelect(project._id)}
              >
                <p className={styles.itemTitle}>{i+1}.  {project.name}</p>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProjectSelector;
