import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faScrewdriverWrench,
  faBars,
  faInfo,
  faBook,
  faUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import ProjectList from "../ProjectSelector/ProjectList/ProjectList";
import { useNavigate } from "react-router-dom";
import { getSelectorOptionsThunk } from "../../store/actions/selectorOptions";
import { setUserSelectedProject } from "../../store/actions/projects";
import { clearPathologiesList } from "../../store/actions/pathologies";
import { clearDiagnosesList } from "../../store/actions/diagnoses";
import { clearTagsList } from "../../store/actions/tags";
import styles from "./Selector.module.scss";
import { getNextImage, clearCurrentImage } from "../../store/actions/images/next";
import UserManual from "../../assets/user manual/UserManual.pdf";

import { handleSliderToggles, handleSliderlock } from "../../store/actions/slider";

const token = localStorage.getItem("token");

const Selector = (props) => {
  const { projects } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedImage = useSelector((state) => state.images?.currentImage);
  const [showPrompt, setShowPrompt] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showSelector, setShowSelector] = useState(false);

  const currentImage = useSelector((state) => state.next.next);
  const projectAtLoginData = useSelector(
    (state) => state.projects.projectSelectedAtLogin
  );

  const selectedPathologies = useSelector(
    (state) => state.pathologies.pathologies
  );

  const selectedDiagnoses = useSelector((state) => state.diagnoses.diagnoses);

  const getSelectedProject = (state) => {
    return state.projects.projects.find(project => project._id === state.projects.selectedProject);
  }

  const project = useSelector(getSelectedProject);

  const tags = useSelector((state) => state.tags.tags);

  const { open, locked } = useSelector(state => state.slider);
  const { openWindow } = useSelector(state => state.window);

  const handleProjectSelect = async (projectId) => {
    if (
      selectedPathologies.length > 0 ||
      selectedDiagnoses.length > 0 ||
      tags.length > 0
    ) {
      setShowPrompt(true);
      setShowSelector(false);
      setSelectedOption(projectId)
      return; // Exit early, don't switch the project until user decides
    }
    proceedWithProjectSelection(projectId);
  };

  const handleProceed = () => {
    // Clear all pathologies, diagnoses, and tags
    dispatch(clearPathologiesList());
    dispatch(clearDiagnosesList());
    dispatch(clearTagsList());

    setShowPrompt(false);

    if (selectedOption) {
      proceedWithProjectSelection(selectedOption);
    }
  };

  const proceedWithProjectSelection = async (projectId) => {
    setShowSelector(true);
    navigate(`/authorized/projects/${projectId}`);
    await dispatch(setUserSelectedProject(projectId));
    await dispatch(getSelectorOptionsThunk(projectId));
    dispatch(clearCurrentImage(null));
    dispatch(getNextImage(token, projectId, projectAtLoginData));
    setShowSelector(false); 
  };

  const openProjectSelect = () => {
    setShowSelector(!showSelector);
  };

  const expandAllTools = () => {
    console.log("Locked:", locked, "Open:", open); 
    if(locked) {
      dispatch(handleSliderlock());// Ensure slider is unlocked when proceeding
    }
    dispatch(handleSliderToggles()) // Close the slider
  }; 

  const openContactUsLink = () => {
    window.open("https://envisionit.ai/contact-us/", "_blank");
  };

  const downloadFile = (url, filename) => {
    // Create a new anchor element
    const anchor = document.createElement('a');
  
    // Set the href to the URL of the file you want to download
    anchor.href = url;
  
    // Use the filename parameter to set the downloaded file name
    // If the filename is not provided, it will be automatically determined by the browser.
    if (filename) {
      anchor.download = filename;
    }
  
    // Append the anchor to the body, click it, and then remove it
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <div id="addButton" className={`fab open`}>
      <div className="menu">
        <button disabled={locked === true} className="pathologies" onClick={expandAllTools}>
          <FontAwesomeIcon icon={faScrewdriverWrench} size="md" />
          <span>Expand All</span>
        </button>
        {project.version !== 1 && (
            <button 
            onClick={props.toggleOpenNewWindow}
          >
          <FontAwesomeIcon icon={faUpRightFromSquare} size="md"/> 
            <span>Pop Viewer</span>
          </button>
        )}
        <button onClick={() => openProjectSelect()}>
          <FontAwesomeIcon icon={faBars} size="md" />
          <span>Projects</span>
        </button>
        <button onClick={openContactUsLink}>
          <FontAwesomeIcon icon={faInfo} size="md" />
          <span>Contact Us</span>
        </button>
        <button onClick={() => downloadFile(UserManual, 'UserManual.pdf')}>
          <FontAwesomeIcon icon={faBook} size="md" />
          <span>User Guide</span>
        </button>
      </div>
      {showSelector && (
        <ProjectList
          handleProjectSelect={handleProjectSelect}
          options={projects}
          className={styles.projectSelector}
        />
      )}
      {showPrompt && (
        <div className={styles.prompt}>
          <p>
            Switching to another project without saving changes will remove any
            annotations. Would you like to proceed?
          </p>
          <div className={styles.prompts}>
            <button onClick={() => setShowPrompt(false)}>Cancel</button>
            <button onClick={handleProceed}>Proceed</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Selector;
