/**
 * Helper function to get the color for a given value from an array of options
 * @param {string} value - The value to find the color for
 * @param {array} options - The array of options to search
 * @param {string} defaultColor - The default color to return if no matching color is found
 * @returns {string} - The color for the given value, or the default color if no match is found
 */
export const getColorForValue = (value, options) => {
  // console.log('other look')
  // console.log('other look',options.find((option) => option.value === value))
  if (options && options.length > 0) {
    const option = options.find((option) => option.value === value);
    if (option) {
      return option.color;
    }
  }
};
