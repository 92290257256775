import axios from "axios";
import { createLabel } from "../../reducers/label/labelSlice";
import { apiRoute } from "../../../helpers";

export const createImageLabel = (diagnosisData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiRoute()}/api/v1/training/labels/${diagnosisData.image}`,
      diagnosisData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log('diagnosisData in createImageLabel', diagnosisData);
    console.log('response.data', response.data);

    await dispatch(createLabel(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};
