import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pathologies: [],
  updateList: [],
  selectedBoundingBox: null,
  freehandDrawings: [],
  rectanglesFromFreehand: [], 
};

const pathologiesSlicer = createSlice({
  name: "pathologies",
  initialState,
  reducers: {
    addPathologies: (state, action) => {
      state.pathologies = [
        ...state.pathologies,
        ...action.payload.filter(
          (p) => !state.pathologies.some((p2) => p2.id === p.id)
        ),
      ];
      return state;
    },

    // removePathologies: (state, action) => {
    //   const pathologyId = action.payload;
    //   state.pathologies = state.pathologies.filter((p) => p.id !== pathologyId);
    //   console.log('pathologyId', pathologyId)
    //   return state;
    // },
    removePathologies: (state, action) => {
      const pathologyId = action.payload;
      state.pathologies = state.pathologies.filter((p) => p.id !== pathologyId);
      state.freehandDrawings = state.freehandDrawings.filter((d) => d.id !== pathologyId);
      console.log('pathologyId', pathologyId);
      return state;
    },
    updatePathologies: (state, action) => {
      // here we need to find the pathology and update it with the new coordinates if it exists in the state already update it otherwise do nothing
      const updatedPathologies = action.payload;
      console.log('paths Update', action.payload)
      // updatedPathologies.forEach((updatedPathology) => {
      //   const index = state.pathologies.findIndex(
      //     (p) => p.id === updatedPathology.id
      //   );
      //   if (index !== -1) {
          state.pathologies= action.payload;
      //   }
      // });
      return state;
    },
    // updatePathologies: (state, action) => {
    //   // here we need to find the pathology and update it with the new coordinates if it exists in the state already update it otherwise do nothing
    //   const updatedPathologies = action.payload;
    //   console.log('paths Update', action.payload)
    //   // updatedPathologies.forEach((updatedPathology) => {
    //   //   const index = state.pathologies.findIndex(
    //   //     (p) => p.id === updatedPathology.id
    //   //   );
    //   //   if (index !== -1) {
    //       state.pathologies= action.payload;
    //   //   }
    //   // });
    //   return state;
    // },

    updateList: (state, action) => {
      const updatedPathologies = action.payload;

      updatedPathologies.forEach((updatedPathology) => {
        const index = state.updateList.findIndex(
          (p) => p.id === updatedPathology.id
        );
        if (index !== -1) {
          state.updateList[index] = updatedPathology;
        }
      });

      state.updateList = [
        ...state.updateList,
        ...updatedPathologies.filter(
          (p) => !state.updateList.some((p2) => p2.id === p.id)
        ),
      ];

      return state;
    },

    // update all the pathologies in the list
    updateAllPathologies: (state, action) => {
      state.pathologies = action.payload;
      return state;
    },

    clearPathologies: (state, action) => {
      state.pathologies = [];
      return state;
    },
    // set the current selected pathology
    setCurrentSelectedPathology: (state, action) => {
      state.selectedBoundingBox = action.payload;
      return state;
    },
     // New reducer for adding freehand drawings
     addFreehandDrawing: (state, action) => {
      // Expecting a single drawing object
      const drawing = action.payload;
  
      if (drawing && typeof drawing === 'object' && drawing.id) {
          state.freehandDrawings.push(drawing);
      } else {
          console.error("Invalid freehand drawing payload:", drawing);
      }
    },  

    // Add new action for updating freehand drawings
    updateFreehandDrawings: (state, action) => {
      const updatedFreehandDrawings = action.payload;
      state.freehandDrawings = updatedFreehandDrawings;
      return state;
    },
    
     clearFreehandDrawings: (state) => {
       state.freehandDrawings = [];
     },
     addRectanglesFromFreehand: (state, action) => {
      const rectangles = action.payload; // Ensure payload is an array
      
      if (Array.isArray(rectangles)) {
        state.rectanglesFromFreehand.push(...rectangles);
      } else {
        console.error('Payload is not an array', rectangles);
      }
    },    
    updateRectanglesFromFreehand: (state, action) => {
      const updatedRectanglesFromFreehand = action.payload;
      state.rectanglesFromFreehand = updatedRectanglesFromFreehand;
      return state;
    },
    clearRectanglesFromFreehand: (state) => {
      state.rectanglesFromFreehand = [];
    },    
    updateFreehandDrawingsNamesAndColor: (state) => {
      var recs = state.rectanglesFromFreehand
      var freeHands = state.freehandDrawings

      for (var i = 0; i < recs.length; i++){
        freeHands[i].color = recs[i].color
        freeHands[i].text= recs[i].text
      }
      state.freehandDrawings  = freeHands
      return state;
    },
  },
});

export const {
  addPathologies,
  removePathologies,
  updatePathologies,
  clearPathologies,
  updatePathologyCoordinates,
  updateList,
  updateAllPathologies,
  setCurrentSelectedPathology,
  addFreehandDrawing,
  updateFreehandDrawings,
  clearFreehandDrawings,
  addRectanglesFromFreehand,
  updateRectanglesFromFreehand,
  clearRectanglesFromFreehand,
  updateFreehandDrawingsNamesAndColor
} = pathologiesSlicer.actions;
export default pathologiesSlicer.reducer;
