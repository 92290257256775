import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  next: null,
  projectAtLogin: null,
};

const nextImageSlice = createSlice({
  name: "next",
  initialState,
  reducers: {
    setNextImage: (state, action) => {
      state.next = action.payload;
      state.projectAtLogin = action.payload.projectAtLogin;
      console.log('set next image')
    },
    setLabelled: (state, action) => {
      state.labelled = action.payload;
    },
    clearNextImage: (state, action) => {
      state.next = action.payload;
    },

  },
});

export const { setNextImage, clearNextImage } = nextImageSlice.actions;
export default nextImageSlice.reducer;
