import {
  addPathologies,
  removePathologies,
  updateAllPathologies,
  clearPathologies,
  updatePathologies,
  updatePathologyCoordinates,
  updateList,
  setCurrentSelectedPathology,
  addFreehandDrawing,
  clearFreehandDrawings,
  updateFreehandDrawings,
  addRectanglesFromFreehand, 
  updateRectanglesFromFreehand,
  clearRectanglesFromFreehand,// Import new action
  updateFreehandDrawingsNamesAndColor
} from "../../reducers/pathologies/pathologiesSlicer";

export const addNewPathologies = (pathologies) => async (dispatch) => {
  try {
    if (!pathologies[0].color){
    pathologies[0].color = '#bd9e04'
    // console.log('path',pathologies)
    }
    await dispatch(addPathologies(pathologies));
    return;
  } catch (error) {
    throw error;
  }
};

export const removePathology = (pathology) => async (dispatch) => {
  try {
    await dispatch(removePathologies(pathology));
    return;
  } catch (error) {
    throw error;
  }
};

export const editPathology = (pathology) => async (dispatch) => {
  try {
    await dispatch(updatePathologies(pathology));
    return;
  } catch (error) {
    throw error;
  }
};

export const updatePathologiesList = (pathologies) => async (dispatch) => {
  try {
    await dispatch(updatePathologies(pathologies));
    return;
  } catch (error) {
    throw error;
  }
};

export const clearPathologiesList = () => async (dispatch) => {
  try {
    await dispatch(clearPathologies());
    return;
  } catch (error) {
    throw error;
  }
};

export const updatePathologiesCoords = (pathologies) => async (dispatch) => {
  try {
    await dispatch(updatePathologyCoordinates(pathologies));
    return;
  } catch (error) {
    throw error;
  }
};

export const updatePathologiesListCoords =
  (pathologies) => async (dispatch) => {
    try {
      await dispatch(updateList(pathologies));
      return;
    } catch (error) {
      throw error;
    }
  };

// update all bounding boxes on the canvas
export const updateAllBoundingBoxes = (boxes) => async (dispatch) => {
  try {
    await dispatch(updateAllPathologies(boxes));
    return;
  } catch (error) {
    throw error;
  }
};

export const getSelectedBoundingBox = (pathology) => async (dispatch) => {
  try {
    await dispatch(setCurrentSelectedPathology(pathology));
    return;
  } catch (error) {
    throw error;
  }
};

export const addFreehandPathology = (drawing) => async (dispatch) => {
  try {
    // Ensure the drawing object is dispatched directly
    await dispatch(addFreehandDrawing(drawing));
    console.log(drawing)
    return;
  } catch (error) {
    console.error("Error dispatching addFreehandPathology:", error);
    throw error;
  }
};

export const clearFreehandPathologiesList = () => async (dispatch) => {
  try {
    await dispatch(clearFreehandDrawings());
    return;
  } catch (error) {
    throw error;
  }
};

export const updateFreehandDrawingsList = (pathologies) => async (dispatch) => {
  try {
    console.log('dispatched');
    await dispatch(updateFreehandDrawings(pathologies));
    return;
  } catch (error) {
    throw error;
  }
};

export const addNewRectanglesFromFreehand = (rectangles) => async (dispatch) => {
  try {
    // Ensure the payload is an array
    if (!Array.isArray(rectangles)) {
      throw new TypeError("Payload should be an array of rectangles.");
    }
    await dispatch(addRectanglesFromFreehand(rectangles));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateRectanglesFromFreehandList = (rectangles) => async (dispatch) => {
  try {
    console.log('dispatched');
    await dispatch(updateRectanglesFromFreehand(rectangles));
    return;
  } catch (error) {
    throw error;
  }
};

export const clearReactanglesFromFreehandList = () => async (dispatch) => {
  try {
    await dispatch(clearRectanglesFromFreehand());
    return;
  } catch (error) {
    throw error;
  }
};

export const updateUpdateFreehandDrawingsNamesAndColor = () => async (dispatch) => {
  try {
    await dispatch(updateFreehandDrawingsNamesAndColor());
    return;
  } catch (error) {
    throw error;
  }
};

