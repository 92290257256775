import axios from 'axios';
import { apiRoute } from '../helpers';

export const loginService = {
  authenticateLogin
};

async function authenticateLogin() {
  try {
    const loginUrl = `${apiRoute()}/auth/v1/login?redirect=labeller`;
    const response = await axios.get(loginUrl);
    console.log('resp', response);
    return response.data.url;
  } catch (error) {
    console.error('Error during login request:', error);
    throw error;
  }
}

