import { getSelectorOptions } from "../../reducers/selectorOptions/selectorOptionsSlicer";
import axios from "axios";
import { apiRoute } from "../../../helpers";

export const getSelectorOptionsThunk = (projectId) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${apiRoute()}/api/v1/training/images/projects/${projectId}/diagnosis-tags-pathologies`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(getSelectorOptions(response.data));
      console.log("response", response.data);
    } catch (error) {
      console.error('Failed to fetch selector options:', error);
      // Optionally stop polling if a critical error occurs
    }
};


