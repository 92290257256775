import { apiRoute, authHeader, handleResponse } from '../helpers'

export const rejectService = {
  reject
}

function reject (projectId, imageIds, reason, phase) {
    console.log("authHeader", authHeader())
  const requestOptions = {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json' },
    body: JSON.stringify({
      projectId: projectId,
      imageIds: imageIds,
      reason: reason,
      phase: phase
    })
  }

  return fetch(apiRoute() + '/api/v1/admin/training/remove-images/', requestOptions).then(handleResponse)
}
