import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  images: [],
  loaded: false,
  currentImage: null,
  firstImage: null,
  previousImages: [],
};

const imagesSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setAllImages: (state, action) => {
      state.images = [...state.images, ...action.payload];
    },
    setImagesLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setCurrentImage: (state, action) => {
      console.log('set current1',action.payload)
      console.log('set current2',state)
      state.currentImage = action.payload;
    },
    setFirstImage: (state, action) => {
        state.firstImage = action.payload
    },
    setPreviousImages: (state, action) => {
      state.previousImages = [...state.previousImages, ...action.payload];
    },
    // update pathologies on the current Image
    updateCurrentImage(state, action) {
      state.currentImage = action.payload;
    },

    clearCurrentImage(state, action) {
      state.currentImage.pathologies = {};
    },
  },
});

export const {
  setAllImages,
  setImagesLoaded,
  setCurrentImage,
  setFirstImage,
  updateCurrentImage,
  clearCurrentImage,
} = imagesSlice.actions;
export default imagesSlice.reducer;
