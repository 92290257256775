import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiRoute } from '../helpers'
import axios from 'axios';
import { setCurrentImage, clearCurrentImage } from '../store/reducers/images/imagesSlicer';
import { addFreehandPathology, updatePathologiesList, addNewRectanglesFromFreehand, updateFreehandDrawingsList, clearPathologiesList, updateUpdateFreehandDrawingsNamesAndColor } from '../store/actions/pathologies';

const modalStyles = {
  backgroundColor: '#081624',
  color: '#fff',
  border: '1px solid #ccc',
  padding: '20px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 10000,
  width: '400px',
  boxSizing: 'border-box'
};

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  zIndex: 9999
};

const inputContainerStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  marginTop: '10px'
};

const inputGroupStyles = {
  display: 'flex',
  flexDirection: 'row',
  flex: '1 1 auto',
  width: '100%',
  boxSizing: 'border-box',
  marginBottom: '10px',
  gap: '16px',  
};

const inputLabelStyles = {
  fontSize: '0.8rem',
  color: '#fff'
};

const inputStyles = {
  flex: '1 1 45%',
  padding: '5px',
  fontSize: '0.8rem',
  height: '25px',
  boxSizing: 'border-box',
  marginRight: '5px'
};

const buttonContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px'
};

const cancelButtonStyle = {
  backgroundColor: 'red',
  padding: '10px 20px',
  fontSize: '0.8rem',
  color: '#fff',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '6px'
};

const proceedButtonStyle = {
  backgroundColor: 'green',
  padding: '10px 20px',
  fontSize: '0.8rem',
  color: '#fff',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '6px'
};

const ViewerPolling = ({ startPolling, selectedImageId, clickTimestamp }) => {
  const [dataExists, setDataExists] = useState(false);
  const [newData, setNewData] = useState([]);
  const [imageSet, setImageSet] = useState([]);
  const [currentDrawings, setCurrentDrawings] = useState([]);
  const [currentImageId, setCurrentImageId] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [inputData, setInputData] = useState({});
  const [showInitialModal, setShowInitialModal] = useState(false);
  const [showInputModal, setShowInputModal] = useState(false);
  const [img_raw_data, set_img_raw_data] = useState(false);
  const dispatch = useDispatch();

  const selectedImage = useSelector((state) => state.images?.currentImage);

  const [selectedPathologies, setSelectedPathologies] = useState([]);

  const handlePathologyChange = (index, option) => {
    const updatedPathologies = [...selectedPathologies];
    updatedPathologies[index] = option;
    setSelectedPathologies(updatedPathologies);
    // Optionally update state or perform actions based on the selected option
  };

  useEffect(() => {
    if (startPolling) {
      const interval = setInterval(async () => {
        try {
          const response = await axios.get(
            apiRoute() + '/api/v1/training/labels/poll/664c32fff618de0013a2b959/2024-05-23T07:10:28.285+00:00',
            { headers: { Authorization: `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json' } }
          );
  
          if (response.data?.img?.length > 0) {
            setNewData(response.data);
            const imagesData = aggregateDrawingsByImage(response.data.img);
            setImageSet(prevImageSet => ({ ...prevImageSet, ...imagesData }));
            setDataExists(true);
            setShowInitialModal(true);
  
            clearInterval(interval);  // Stop polling once data is received and the modal is shown
          } else {
            console.log('No new data');
          }
        } catch (error) {
          console.error('Error polling service:', error);
          clearInterval(interval);  // Stop polling on error
        }
      }, 20000);  // Polling interval increased to 20 seconds
  
      return () => clearInterval(interval);
    }
  }, [startPolling, selectedImageId, clickTimestamp]);

  const aggregateDrawingsByImage = (imageData) => {
    var _img = {}
    const groupedByImageId = imageData.reduce((acc, item) => {
      if (!_img[item.image]){
        _img[item.image] = selectedImage._id === item.image ? selectedImage : selectedImage.otherImages.find(_i => item.image === _i._id)
      }
      if (!acc[item.image]) {
        acc[item.image] = [];
      }
      acc[item.image].push(item.freehandpoints);
      return acc;
    }, {});
    set_img_raw_data(_img)
    console.log('img data 12321', _img)
    return groupedByImageId;
  };

  useEffect(() => {
    if (newData.img && Object.keys(imageSet).length > 0) {
        const firstImageId = Object.keys(imageSet)[0];
        setCurrentImageIndex(0);
        setCurrentImageId(firstImageId);
        console.log(firstImageId)
        setCurrentDrawings(imageSet[firstImageId]);
        resetInputData(imageSet[firstImageId].length);
    }
  }, [newData]);

  const handleProceedFromInitial = () => {
    console.log(img_raw_data)
    console.log(img_raw_data[currentImageId])
    dispatch(setCurrentImage(img_raw_data[currentImageId]))
    displayDrawingsOnImage(currentImageId)
    setShowInitialModal(false);
    setShowInputModal(true);
  };

  const handleCancel = () => {
    setShowInitialModal(false);
    setShowInputModal(false);
  };

  const handleInputChange = (index, field, value) => {
    setInputData(prevState => ({
        ...prevState,
        [index]: {
            ...prevState[index],
            [field]: value,
        },
    }));
};

useEffect(() => {
  if (currentImageId && imageSet[currentImageId]) {
      // processImage(currentImageId);
  }
}, [currentImageId, imageSet]);

  const isLastDrawingComplete = () => {
    return Object.values(inputData).every(entry => entry.name && entry.color);
  };

  const handleProceed = () => {
    if (isLastDrawingComplete()) {
        const newIndex = currentImageIndex + 1;
        if (newIndex < Object.keys(imageSet).length) {
            const nextImageId = Object.keys(imageSet)[newIndex];
            setCurrentImageIndex(newIndex);
            setCurrentImageId(nextImageId);
            console.log(nextImageId)
            // setCurrentImage(nextImageId);
            setCurrentDrawings(imageSet[nextImageId]);
            setShowInputModal(true);
            // processImage(nextImageId);  
            console.log(img_raw_data)
            console.log('next image', img_raw_data[nextImageId])
            dispatch(setCurrentImage(img_raw_data[nextImageId]))
            console.log(imageSet[nextImageId])
            sleep(2000).then(() => {});
            processFreehandData(Object.keys(imageSet)[newIndex-1])
            displayDrawingsOnImage(nextImageId)
            resetInputData(imageSet[nextImageId].length);
        } else {
            processFreehandData(Object.keys(imageSet)[newIndex-1], true)
            setShowInputModal(false);
            dispatch(clearCurrentImage());  
        }
    }
};
  
  const resetInputData = (count) => {
    setInputData(Array.from({ length: count }, () => ({ name: '', color: '' })));
  };

  const processFreehandData = (_id, is_last = false) => {
    const pathologies = [];
    const freehandDrawings = [];
  
    
    var i = 0
    const drawings = imageSet[_id];
    drawings.forEach(drawing => {
      const name = inputData[i].name || 'Pathology';
      const color = inputData[i].color || '#000';
      i +=1
      const commonId = `drawing-${currentImageId}-${i}`; 
      
      const xs = drawing.map(point => point.x);
      const ys = drawing.map(point => point.y);
      const minX = Math.min(...xs);
      const minY = Math.min(...ys);
      const maxX = Math.max(...xs);
      const maxY = Math.max(...ys);
    
      const width = maxX - minX;
      const height = maxY - minY;
    
      if (width >= 10 && height >= 10) { 
        const newRect = {
          id: commonId,
          x: minX,
          y: minY,
          width: width,
          height: height,
          color: color,
          text: name,
          imgId: selectedImage._id
        };
        console.log('paths ', newRect)
        // pathologies.push(newRect);
        sleep(2000).then(() => {
          dispatch(addNewRectanglesFromFreehand([newRect]));
          if (is_last){
            dispatch(updateUpdateFreehandDrawingsNamesAndColor())
          }
        });
        
      }
      
    });

  };
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const displayDrawingsOnImage = (_id) => {
    var i = 0
    const drawings = imageSet[_id];
    drawings.forEach(drawing => {
      i +=1
      const freehandShape = {
        id: `drawing-${_id}-${i}`,
        points: drawing.map(point => ({
          x: point.x,
          y: point.y
        })),
        stroke: '#FF0000', 
        strokeWidth: 2,
        fill: '#FF0000', 
        opacity: 0.5,
        text: 'Pending Name', 
        imgId: _id
      };
      console.log('freehand shape', freehandShape);
      sleep(2000).then(() => { dispatch(addFreehandPathology(freehandShape));});
      
    });
  };

  return (
    <>
      {showInitialModal && (
        <>
          <div style={modalStyles}>
            <h4>New Data Available</h4>
            <p>New bounding boxes have been detected.</p>
            <div style={buttonContainerStyles}>
              <button style={cancelButtonStyle} onClick={handleCancel}>Cancel</button>
              <button style={proceedButtonStyle} onClick={handleProceedFromInitial}>Proceed</button>
            </div>
          </div>
        </>
      )}

      {showInputModal && (
        <>
          <div style={modalStyles}>
            <h4>Input Data for Drawings on Image {currentImageId}</h4>
            <div style={inputContainerStyles}>
              {currentDrawings?.map((drawingPoints, index) => (
                <div key={index} style={inputGroupStyles}>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 45%' }}>
                    <label style={inputLabelStyles}>Drawing {index + 1} Name</label>
                    <input
                      type="text"
                      placeholder={`Enter name for Drawing ${index + 1}`}
                      value={inputData[index]?.name || ''}
                      onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                      style={inputStyles}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 45%' }}>
                    <label style={inputLabelStyles}>Drawing {index + 1} Color</label>
                    <input
                      type="text"
                      placeholder={`Enter color for Drawing ${index + 1}`}
                      value={inputData[index]?.color || ''}
                      onChange={(e) => handleInputChange(index, 'color', e.target.value)}
                      style={inputStyles}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div style={buttonContainerStyles}>
              <button style={cancelButtonStyle} onClick={handleCancel}>Cancel</button>
              <button 
                onClick={handleProceed}
                disabled={!isLastDrawingComplete()}
                style={proceedButtonStyle}
              >
                Next Image
              </button>
            </div>
          </div>
        </>
      )}

    </>
  );
};

export default ViewerPolling;
